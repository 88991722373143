
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import LandingBackground from '../features/outlets/components/LandingBackground';
import OutletSearchCard from '../features/outlets/components/searchOutlets/OutletSearchCard';
import Page from '../features/layout/components/Page';
import styles from './index.module.scss';
function HomePage() {
    return (<Page>
      <LandingBackground className={styles.imgContainer}>
        <OutletSearchCard />
      </LandingBackground>
    </Page>);
}
export default HomePage;

    async function __Next_Translate__getStaticProps__1933f98dc48__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1933f98dc48__ as getStaticProps }
  